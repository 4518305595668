<template>
  <div class="table-container">
    <table
      class="table table-condensed table-bordered table-hover dataTable table-striped  table-responsive"
    >
      <thead>
        <tr>
          <th>{{ $t("equipment") }}</th>
          <th>{{ $t("model") }}</th>
          <th>{{ $t("customer") }}</th>
          <th>{{ $t("s_n") }}</th>
          <th>{{ $t("connection") }}</th>
          <th>{{ $tc("alarm") }}</th>
          <th>
            <span v-if="isLatest">{{ $t("hours_alarms_24") }} </span>
            <span v-else>
              {{ $t("hours_alarms") }}
              <span class="small text-info">({{ $t("not_latest") }})</span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody v-if="equipment">
        <tr>
          <td>{{ equipment_name }}</td>
          <td>{{ model }}</td>
          <td>{{ customer }}</td>
          <td>{{ serial_number }}</td>
          <td class="">
            <span v-if="equipment.is_connected" class="text-success">
              <i class="fa fa-check"></i> {{ $tc("connected") }}
            </span>
            <span v-else class="text-danger">
              <i class="fa fa-exclamation-triangle"></i>
              {{ $tc("disconnected") }}
            </span>
          </td>
          <td class="">
            <span v-if="equipment.has_active_alarms" class="text-danger">
              <i class="fa fa-exclamation-triangle"></i> {{ $t("alarmed") }}
            </span>
            <span v-else class="text-default"> - </span>
          </td>
          <td class="">
            <span v-if="equipment.has_active_alarms" class="text-info">
              <i class="fa fa-information-triangle"></i>
            </span>
            <span class="text-default"> {{ nAlarms }} </span>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="9">{{ $t("equipment_not_selected") }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "EquipmentTableDetailsReport",
  props: {
    equipment: Object,
    display: Object,
    panelName: String
  },
  components: {},
  data() {
    return {
      lastData: {}
    };
  },
  methods: {
    lastEquipmentData: function(data_id) {
      let data =
        this.$store.getters["dashboard/dataList"].find(
          (item) => item.id == data_id && item.current_value
        ) || null;
      if (data) {
        this.lastData[data_id] = data;
      }
      return data;
    }
  },
  computed: {
    equipment_name: function() {
      // Messer especific  - todo: template should be configured with the compatible property name
      return (this.equipment.nome.replace(/\_/g, " ") || "").toUpperCase();
    },
    model: function() {
      // Messer especific  - todo: template should be configured with the compatible property name
      return (this.equipment.modelo || "").toUpperCase();
    },
    customer: function() {
      // Messer especific - todo: template should be configured with the compatible property name
      return (this.equipment.cliente || "").toUpperCase();
    },
    serial_number: function() {
      // Messer especific - todo: template should be configured with the compatible property name
      return (
        this.equipment.numero_serie_maquina ||
        this.equipment.numero_serie ||
        ""
      ).toUpperCase();
    },
    panelOptions: function() {
      var panel = this.panel || null;
      return (panel && panel.options) || null;
    },
    panel: function() {
      var self = this;
      var lst = ((self.display && self.display.panels) || []).filter(function(
        i
      ) {
        return i.name == self.panelName;
      });
      return (lst.length && lst[0]) || null;
    },
    title: function() {
      var panel = this.panel;
      return (panel && panel.title) || this.equipment.nome;
    },
    historyAlarmCounter() {
      var self = this;
      let vlr =
        self.equipment &&
        self.equipment.alarms_history &&
        self.equipment.alarms_history.length;
      return vlr || 0;
    },
    isLatest() {
      return true;
    },
    nAlarms() {
      if (this.panelOptions && this.panelOptions?.alarm_counter?.data_id) {
        let data = self.lastEquipmentData(
          this.panelOptions.alarm_counter.data_id
        );
        if (data) {
          return parseFloat(data.current_value.value).toFixed(0);
        }
      }
      return this.historyAlarmCounter || "-";
    }
  }
};
</script>

<style scoped>
.table-container {
  overflow: auto;
}
.table-container th,
table-container td {
  vertical-align: top;
}
.nowrap {
  white-space: nowrap;
}
</style>
